import { useBreakpoints } from '@vueuse/core';
import { BREAKPOINTS } from '@/lib/constants';

export function useResponsiveBreakpoints() {
  const breakpoints = useBreakpoints(BREAKPOINTS);
  const isMobile = breakpoints.smaller('tablet');
  const isDesktop = breakpoints.greater('desktop');

  return { isMobile, isDesktop };
}
